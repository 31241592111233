<template>
  <div id="uploadDocsPrefernce">
    <div
      v-loading="loading"
      :element-loading-text="loadingText"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <h3
        class="text-center text-dark fw-normal fs-4 mb-1"
        style="font-family: Inter"
      >
        Upload Documents
      </h3>
      <el-row class="block-types el-row-center-items select-your-preference">
        <el-col
          :xl="24"
          :lg="24"
          :md="24"
          :sm="24"
          :xs="24"
          style="text-align: center"
          class="select-your-preference-upload-file"
        >
          <div
            class="upload-file-container"
            style="width: 518px; height: 288px"
          >
            <el-upload
              class="upload-file-el-upload"
              drag
              name="logo"
              :on-change="uploadFile"
              action
              :show-file-list="false"
              :auto-upload="false"
              accept=".doc, .pdf, .docx, .txt, .png, .jpg, .jpeg, .xls, .xlsx, .xlsm,"
              multiple
            >
              <div class="upload-file">
                <div class="icon py-2">
                  <img
                    src="@/assets/img/icons/upload-file.svg"
                    alt="Upload File"
                    height="40"
                    width="100"
                  />
                </div>
                <h4 class="fw-normal blue pb-2">Upload A File</h4>
                <p class="text-dark fs-9 mb-0">Drag and drop files here</p>
                <p class="text-muted fs-9 opacity-60 upload-a-file-bottom-text">
                  File supported: PDF, DOC, JPG, PNG, TXT
                </p>
              </div>
            </el-upload>
            <div class="upload-from-other-container">
              <p class="
                  text-muted
                  fs-9
                  opacity-60
                  upload-a-file-upload-from-text
                ">
                Upload From
              </p>
              <div class="from-others">
                <div class="image" v-if="getAccess('documents','blank_doc','')">
                  <el-tooltip
                    effect="light"
                    placement="bottom-start"
                    content="Blank Document"
                  >
                    <img
                      src="@/assets/img/icons/Blank.svg"
                      alt="Nimble logo"
                      class="upload-icon"
                      @click="chooseBlankDocument"
                    />
                  </el-tooltip>
                </div>
                <div class="image" v-if="getAccess('documents','fromTemplate','')">
                  <el-tooltip
                    effect="light"
                    placement="bottom-start"
                    content="Upload From Templates"
                  >
                    <img
                      src="@/assets/img/icons/templates.svg"
                      alt="Upload File"
                      height="28"
                      style="cursor: pointer"
                      @click="createTemplate"
                    />
                  </el-tooltip>
                </div>
                <div v-if="getAccess('documents','googleDrive','')"
                  class="image"
                  v-on:click="
                    (e) =>
                      handleUploadFrom(e, [
                        'googledrive',
                        'dropbox',
                        'onedrive',
                      ])
                  "
                >
                  <el-tooltip
                  effect="light"
                    placeholder="top-start"
                    content="Google Drive"
                  >
                    <img
                      src="@/assets/img/google-drive.svg"
                      alt="Nimble logo"
                      class="upload-icon"
                    />
                  </el-tooltip>
                </div>
                <div v-if="getAccess('documents','dropBox','')"
                  class="image"
                  v-on:click="
                    (e) =>
                      handleUploadFrom(e, [
                        'dropbox',
                        'googledrive',
                        'onedrive',
                      ])
                  "
                >
                  <el-tooltip
                    placeholder="top-start"
                    content="Drop Box"
                    effect="light"
                  >
                    <img
                      src="@/assets/img/dropbox.svg"
                      alt="Nimble logo"
                      class="upload-icon"
                    />
                  </el-tooltip>
                </div>
                <div v-if="getAccess('documents','oneDrive','')"
                  class="image"
                  v-on:click="
                    (e) =>
                      handleUploadFrom(e, [
                        'onedrive',
                        'googledrive',
                        'dropbox',
                      ])
                  "
                >
                  <el-tooltip
                    effect="light"
                    placement="bottom-start"
                    content="One Drive"
                  >
                    <img
                      src="@/assets/img/onedrive.svg"
                      alt="Nimble logo"
                      class="upload-icon"
                    />
                  </el-tooltip>
                </div>
                <div v-if="getAccess('documents','sharePoint','')"
                  class="image"
                  @click="getDocumentsFromSharepoint"
                >
                  <el-tooltip
                    placeholder="top-start"
                    content="SharePoint"
                    effect="light"
                  >
                    <img
                      src="@/assets/img/Sharepoint.svg"
                      alt="SharePoint Logo"
                      class="upload-icon"
                    />
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
          <span v-if="logoError">{{ logoError }}</span>
        </el-col>
      </el-row>

      <el-row
        type="flex"
        :gutter="30"
        justify="center"
        class="name-types"
      >
        <el-col :span="14">
          <draggable
            v-if="files && files.length"
            v-model="files"
          >
            <transition-group
              tag="div"
              name="flip-list"
              id="field-group"
            >
              <div
                class="filename"
                v-for="(file, index) in files"
                :key="JSON.stringify(file)"
              >
                <a class="el-upload-list__item-name">
                  <img
                    :src="require('@/assets/img/icons/upload-active.svg')"
                    alt="icon"
                    class="img-active"
                  />
                  <img
                    v-if="
                      file.raw.type == 'image/jpeg' ||
                      file.raw.type == 'image/jpg'
                    "
                    :src="require('@/assets/img/icons/JPG.svg')"
                    alt="icon"
                    class="img-fluid"
                  />
                  <img
                    v-else-if="file.raw.type == 'image/png'"
                    :src="require('@/assets/img/icons/PNG.svg')"
                    alt="icon"
                    class="img-fluid"
                  />

                  <img
                    v-else
                    :src="require('@/assets/img/icons/pdf.svg')"
                    alt="icon"
                    class="img-fluid"
                  />
                  {{
                    file.name
                  }}
                </a>
                <div class="direction-buttons">
                  <el-button
                    type="text"
                    icon="el-icon-bottom"
                    class="directions"
                    @click="moveDown(index)"
                  ></el-button>
                  <el-button
                    type="text"
                    icon="el-icon-top"
                    class="directions"
                    @click="moveUp(index)"
                  ></el-button>
                  <a @click="deleteItem(index)">
                    <i class="el-icon-circle-close"></i>
                  </a>
                </div>
              </div>
            </transition-group>
          </draggable>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        :gutter="30"
        justify="center"
        v-if="files && files.length > 1"
        class="mt-1"
      >
        <el-col :span="14">
          <el-checkbox v-model="isMergeDocument">Process it as a single document?</el-checkbox>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        :gutter="30"
        justify="center"
      >
        <el-col
          :span="6"
          :offset="4"
        >
          <div v-if="files && files.length && files.length > 0">
            <el-button
              type="danger"
              class="text-center upload-btn"
              @click="checkForMergeOrUpload"
            >Next</el-button>
          </div>
          <!-- <div v-if="isFileIsUploaded" class>
            <el-button
              v-if="isFileIsUploaded"
              class="text-center upload-btn"
              :loading="disabledButton"
              @click="addCompanyDocument"
              type="danger" 
            >Next</el-button>
          </div>-->
        </el-col>
      </el-row>
    </div>
    <dialog-component
      :title="'All Templates'"
      :visible="createDocFromTemplate"
      :width="getIsMobile ? '100%' : '30%'"
      @before-close="createDocFromTemplate=false"
      v-loading="loading"
      v-draggable
    >
      <div class="m-tb-3">
        <span>Select a template</span>
      </div>
      <el-input
        class="search mt-1"
        placeholder="Search a template"
        v-model="search_string"
        clearable
        :style="{ marginBottom: getIsMobile ? '10px' : '0px', width: '100%' }"
      ></el-input>
      <el-scrollbar wrap-style="max-height:350px;padding:-20px">
        <el-table
          width="100%"
          ref="AllTempTable"
          :data="templates"
          highlight-current-row
          @current-change="handleCurrentChange"
        >
          <el-table-column
            min-width="696"
            :sort-method="titleSort"
            selectionMode="single"
            label="Name"
            id="name"
            sortable
            :default-sort="{ prop: 'name', order: 'descending' }"
          >
            <template slot-scope="scope">
              <div class="d-flex">
                <div class="icon-block">
                  <div class="icon">
                    <div
                      class="image-file"
                      v-if="isImage(scope.row.file_type)"
                    >
                      <img
                        src="@/assets/img/icons/image-file.svg"
                        alt="icon"
                        class="img-fluid"
                        style="width: 20px"
                      />
                    </div>
                    <div
                      class="pdf-file"
                      v-if="isPdf(scope.row.file_type)"
                    >
                      <img
                        src="@/assets/img/icons/pdf-file.svg"
                        alt="icon"
                        class="img-fluid"
                        style="width: 20px"
                      />
                    </div>
                    <div class="doc-file">
                      <img
                        v-if="
                          !isImage(scope.row.file_type) &&
                          !isPdf(scope.row.file_type)
                        "
                        src="@/assets/img/icons/doc-file.svg"
                        alt="icon"
                        class="img-fluid"
                        style="width: 20px"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-if="scope.row.title"
                  style="margin-left: 10px"
                >
                  <a class="doc-title">{{
                    getDocumentTitle(scope.row.title)
                  }}</a>
                </div>
                <div @click="handleSendDocumentToUsers(scope.row)"></div>
              </div>
            </template>
          </el-table-column>
          <!--<el-table-column >
        <img v-if="this.currentRow"
        src="@/assets/img/icons/check-circle-fill.svg"/>
          </el-table-column>-->
        </el-table>
      </el-scrollbar>
      <span slot="footer" class="dialog-footere">
        <el-button
          class="mt-1"
          @click="close"
        >Cancel</el-button>
        <el-button
        type="primary"
          @click="goToSendDoc"
        >Continue</el-button>
      </span>
    </dialog-component>
    <dialog-component
      :visible="blanckDocumentModal"
      :title="'Upload a Blank Document'"
      @before-close="resetBlankTemplate"
      :width="getIsMobile ? '100%' : '30%'"
      class="upload-a-blank-document-popup"
    >
      <div v-loading="blanckDocumentData.loading" class="m-tb-3">
        <span>Enter Document name</span>
        <el-input
          placeholder="Enter Document name"
          v-model="blanckDocumentData.name"
          class="mb-2 mt-1"
        ></el-input>
        <span>Select number of pages</span>
        <el-select
          placeholder="Select number of pages"
          v-model="blanckDocumentData.pages"
          style="width: 100%"
          class="mt-1"
        >
          <el-option
            v-for="(i, index) in [1, 2, 3]"
            :key="index"
            :label="i"
            :value="i"
          ></el-option>
        </el-select>
      </div>
      <div slot="footer" class="dialog-footer">
          <el-button @click="resetBlankTemplate">Cancel</el-button>
          <el-button
            :disabled="!blanckDocumentData.name.trim()"
            type="primary"
            @click="uploadBlanckDocument"
          >Continue</el-button>
        </div>
    </dialog-component>
    <el-dialog
      :visible.sync="sharepointDataDialog"
      title="Sharepoint Documents"
      draggable="true"
      width="70%"
      :close-on-click-modal="false"
      v-loading="loading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-row style="margin-bottom: 10px; ">
        <el-col :span="18">
          <div class="breadcrumbs">
            <span
              class="breadcrumb-item"
              @click="handleBreadcrumbClick('')"
            >Home</span>
            <span
              v-for="(folder, index) in pathArray"
              :key="index"
            >
              <span
                class="breadcrumb-item"
                @click="handleBreadcrumbClick(folder)"
              >{{ folder }}</span>
              <span class="breadcrumb-separator">/</span>
            </span>
          </div>
        </el-col>
        <el-col :span="6">
          <el-input
            size="small"
            v-model="searchQuery"
            placeholder="Search..."
            clearable
            @clear="searchQuery = ''"
          ></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-table
          :data="pagedSharepointData"
          :default-sort="{prop: 'lastModified', order: 'descending'}"
          max-height="320"
          ref="table"
          border
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            :selectable="isRowSelectable"
            width="55"
          >
          </el-table-column>
          <el-table-column
            label="Name"
            prop="file"
            sortable
          >
            <template slot-scope="scope">
              <div
                v-if="scope.row.type=='folder'"
                class="folder-column"
                @click="handlePath(scope.row.file)"
              >
                <i class="el-icon-folder"></i>
                {{ scope.row.file }}
              </div>
              <div v-else-if="scope.row.type=='file'">
                {{ scope.row.file }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="lastModified"
            label="LastModified"
            width="190"
            sortable
          >
          </el-table-column>
          <el-table-column
            align="right"
            width="70"
            v-if="isSingleFile"
          >
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.type === 'file'"
                size="mini"
                @click="uploadDocumentFromSharepoint(scope.row)"
              >
                <i class="el-icon-upload2"></i>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-container">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentPageChange"
            :page-sizes="[5,10, 20, 30]"
            :page-size="dialogTablePageSize"
            :current-page="dialogTableCurrentPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="filteredSharepointData.length"
          >
          </el-pagination>
          <el-button
            v-if="selectedRows.length > 0"
            size="mini"
            type="primary"
            @click="uploadSelectedItems"
          >Upload Selected Files</el-button>
        </div>
      </el-row>
    </el-dialog>
    <dialog-component
      :visible="redirectDialog"
      :title="'Register Sharepoint'"
      draggable="true"
      :width="getIsMobile ? '100%' : '30%'"
      @before-close="redirectDialog=false"
    >
      <el-row class="m-tb-3">
        <el-col>
          <div>You haven't registered your sharepoint application yet.Please register to use services.</div>
        </el-col>
      </el-row>
      <el-row slot="footer" class="dialog-footer">
        <el-col
          :span="24"
          align="right"
        >
          <el-tooltip
            class="item"
            effect="dark"
            content="Register App"
            placement="bottom"
          >
            <router-link to="/profilesettings/apps-integrations">
              <el-button
                size="small"
                class="mb-1 ml-2 mr-1"
                style="width: 100px"
                type="primary"
              >
                Register App
              </el-button>
            </router-link>
          </el-tooltip>
        </el-col>
      </el-row>
    </dialog-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex"; 
//import UserDocumentsHelper from "@/mixins/UserDocumentsHelper";
import { bus } from "../../main";
import draggable from "vuedraggable";
import axios from "@/config/axios";
import ComanyDocumentsPresignedURLsHelper from "@/mixins/ComanyDocumentsPresignedURLsHelper";
import DocumentsHelper from "@/mixins/CompanyDocumentsHelper";
import AccessManagement from "@/mixins/AccessManagement";

// import Features from "../../features/options";

// import Select from "../templates/formComponents/Select.vue";
export default {
  // mixins: [UserDocumentsHelper],
  mixins: [DocumentsHelper, ComanyDocumentsPresignedURLsHelper,AccessManagement],
  name: "companyDocuments-UploadDocumentPreference",
  components: {
    draggable
    // Select,
  },

  data() {
    return {
      ext: "",
      // _validFileExtensions : ["jpg", "jpeg", "bmp", "doc", "png","docx","pdf","txt"],
      uploadFromOthersFileName: "",
      uploadFromOthersBlobData: null,
      loadingText: "Fetching data",
      fieldsFormUpload: {
        title: "",
        type: "CUSTOM",
        file_ref_id: "",
        selectedFile: "",
        selectedFileName: ""
      },
      disabledButton: false,
      dialogVisible: false,
      loading: false,
      logoError: "",
      pdfData: "",
      pdfDoc: null,
      authid: null,
      fileUploadUrl: "",
      fileUploadUrls: [],
      fileNames: [],
      fileUploadData: "",
      isMergeDocument: false,
      selectTemp: null,
      files: [],
      templates: [],
      sharepointAccessToken: "",
      sharepointData: [],
      sharepointDataDialog: false,
      dialogTableCurrentPage: 1,
      dialogTablePageSize: 5,
      searchQuery: "",
      path: "",
      selectedRows: [],
      isSingleFile: true,
      redirectDialog: false,
      applicationData: {},
      // filteredSharepointData:[],
      isFileIsUploaded: false,
      showCheckFill: false,
      // dialogVisible: false,
      blanckDocumentModal: false,
      createDocFromTemplate: false,
      search_string: "",
      allTemp: "",
      currentRow: null,
      blanckDocumentData: {
        name: "",
        pages: 1,
        loading: false
      },
      blankTemplatesUrl: [
        "/rest/esignature/files/3cc3b9ac-ff3d-4dfa-90a9-96644c719a15.pdf",
        "/rest/esignature/files/dfc42fc5-4696-4e88-81df-8daf1a7a63dd.pdf",
        "/rest/esignature/files/56647615-6ccc-49c6-9991-b72be2706148.pdf"
      ],
      blankTemplatesData: [
        {
          filename: "page_no_1.pdf",
          source_filename: "0fd406fa-6833-463b-aade-171ec3694353.pdf"
        },
        {
          filename: "page_no_2.pdf",
          source_filename: "21acb043-a936-4399-93c8-65e987361905.pdf"
        },
        {
          filename: "page_no_3.pdf",
          source_filename: "8edab929-dfde-47ba-adf7-c00d63520a27.pdf"
        }
      ],
      pdfFormFields: {},
      pageCount: 0,
      currentDoc: 0
      // blankTemplatesUrl:['/blankTemplate/uploadDir/7dc799ac-f7c1-4740-844e-82c7e9bed010.pdf','/blankTemplate/uploadDir/21acb043-a936-4399-93c8-65e987361905.pdf','/blankTemplate/uploadDir/8edab929-dfde-47ba-adf7-c00d63520a27.pdf']
    };
  },
  computed: {
    ...mapGetters("auth", ["getAuthenticatedUser", "getActiveWorkspace"]),
    ...mapGetters("documents", [
      "getAllTemplates",
      "getFileUploadURL",
      "getCompanyDocumentAddStatus",
      "getNewCompanyDocument",
      "getCreateConfigureDocumentData",
      "getDraftDocumentCreateStatus",
      "getDraftDocumentCreateData",
      "getDocumentSaveAsTemplate"
    ]),
    ...mapGetters("configurableDocuments", ["getConfigurableDocumentDataById"]),
    ...mapGetters("employeeDocuments", [
      "getAuthorizedRepresentativeSignatureStatus",
      "getDocumentUserSignatureStatus",
      "getDocumentApprovalStatus",
      "getDocumentCompletedAfterApproval"
    ]),
    ...mapGetters("s3FileUpload", ["getDocumentUploadStatus"]),
    ...mapGetters("auth", ["getAuthenticationDetails"]),
    ...mapGetters("fileUpload", [
      "getUploadFileDataStatus",
      "getUploadedPdfData",
      "getCopyDocumentStatus",
      "getCopyDocumentUrl"
    ]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile"
    ]),
    ...mapGetters("entities", ["getAllEntities"]),
    ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    getPlan(){
        let planName= this.getActiveWorkspace &&this.getActiveWorkspace.plan_type;
        return planName ? planName.toLowerCase() : '' ;
    },
    uploadHeaders() {
      return {
        Authorization: this.getAuthenticationDetails.access_token
      };
    },
    isImage() {
      return type => (type && type.indexOf("image") > -1 ? true : false);
    },
    isPdf() {
      return type => (type && type.indexOf("pdf") > -1 ? true : false);
    },
    pagedSharepointData() {
      const startIndex =
        (this.dialogTableCurrentPage - 1) * this.dialogTablePageSize;
      const endIndex = startIndex + this.dialogTablePageSize;
      return this.filteredSharepointData.slice(startIndex, endIndex);
    },
    pathArray() {
      return this.path.split("/");
    },
    filteredSharepointData() {
      if (this.searchQuery) {
        const searchTerm = this.searchQuery.toLowerCase();
        return this.sharepointData.filter(item =>
          item.file.toLowerCase().includes(searchTerm)
        );
      } else {
        return this.sharepointData;
      }
    }
  },
  async mounted() {
    this.getCompanyInformation();
    await this.fetchTemplates();
    this.authid =
      this.getAuthenticatedUser && this.getAuthenticatedUser._id
        ? this.getAuthenticatedUser._id
        : null;
  },
  methods: {

    close() {
      this.$emit("close", {});
      this.createDocFromTemplate = false;
    },
    getDocumentTitle(title) {
      if (title.length > 40) {
        return title.slice(0, 38) + "...";
      }
      return title;
    },
    async createTemplate() {
      //
      this.createDocFromTemplate = true;
      this.loading = true;
      this.fetchTemplates();
      this.configurable_document_id;
    },
    async uploadBlanckDocument() {
      this.loading = true;
      this.blanckDocumentData.loading = true;
      this.fieldsFormUpload.title = this.blanckDocumentData.name;
      let selectedFile = this.blankTemplatesData[
        this.blanckDocumentData.pages - 1
      ];

      const copyDocumentData = await this.getCopyDocumentsdata(selectedFile);

      if (copyDocumentData) {
        this.fileUploadUrl = copyDocumentData.path; //
        this.fileUploadUrls = [copyDocumentData.path];
        this.fileNames = [this.blanckDocumentData.name];
        this.disabledButton = false;
        this.isFileIsUploaded = true;
        this.blanckDocumentData.loading = false;
        this.addCompanyDocument();
      } else {
        this.logoError = "Error in uploading file. Please try again..";
      }

      this.blanckDocumentModal = false;
    },
    resetBlankTemplate() {
      this.blanckDocumentModal = false;
      this.blanckDocumentData.name = "";
      this.blanckDocumentData.pages = 1;
    },
    chooseBlankDocument() {
      this.blanckDocumentModal = true;
    },
    FileListItems(files) {
      var b = new DataTransfer();
      for (var i = 0, len = files.length; i < len; i++) b.items.add(files[i]);
      return b.files;
    },
    setCurrent(row) {
      this.$refs.AllTempTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    prepareFieldsData(elements) {
      let data = {};
      elements.forEach(element => {
        if (element.type == "HEADING" || element.type == "PARAGRAPH") {
          data[element.selected_tag] = element.content;
        } else if (element.value) {
          data[element.key] = element.value;
        } else if (element.source) {
          data[element.key] = element.source;
        } else {
          data[element.key] = "";
        }
      });
      return data;
    },
    async fetchTemplates() {
      try {
        let params = { get_all: true,status:'ACTIVE' };
        if (this.search_string) {
          params.search_string = this.search_string;
        }
        await this.$store.dispatch("documents/fetchAllTemplates", params);
        //get_all: true,
        this.templates = this.getAllTemplates.data;
        this.templates =
          this.getAllTemplates && this.getAllTemplates.data
            ? this.getAllTemplates.data
            : [];
            this.templates = this.templates.filter((e)=> !e.is_ck_editor_document)
        this.total =
          this.getAllTemplates && this.getAllTemplates.total
            ? this.getAllTemplates.total
            : 0;
        let configureDocumentIds = this.templates.flatMap(
          temp => temp.configurable_document_id
        );
        await this.getEmployeeDocumentsCount(configureDocumentIds);
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    async handleSendDocumentToUsers(data) {
      try {
        this.loading = true;
        await this.$store.dispatch(
          "configurableDocuments/fetchConfigurableDocumentById",
          { id: data.configurable_document_id }
        );
        let companyId =
          this.getActiveWorkspace && this.getActiveWorkspace.company_id
            ? this.getActiveWorkspace.company_id
            : this.getAuthenticatedUser._id;
        let shareTemplate = false;
        if (
          data.user_id.toString() != this.authid.toString() &&
          companyId.toString() != data.company_id.toString()
        ) {
          shareTemplate = true;
        }
        this.createDraft(data._id, shareTemplate);
      } catch (err) {
        console.log(err);
      }
    },
    async createDraft(document_id, shareTemplate) {
      try {
        let companyDocumentData = this.getConfigurableDocumentDataById;
        let documentData = this.prepareFieldsData(
          companyDocumentData.pages[0].fields
        );
        let params = {
          document_template_id: document_id,
          document_users: companyDocumentData.document_users,
          enforce_signature_order: companyDocumentData.enforce_signature_order,
          document_data: documentData,
          companayDocumentData: companyDocumentData,
          is_document: false,
          configurabledocumentid: this.getConfigurableDocumentDataById,
          isShareTemplate: shareTemplate
        };
        await this.$store.dispatch("documents/createDraftDocument", params);

        if (this.getDraftDocumentCreateStatus) {
          let draftId = this.getDraftDocumentCreateData.data._id;
          this.$router.push({
            name: "employee-documents-custom-document-edit",
            params: {
              employee_document_id: draftId
            }
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    goToSendDoc() {
      if (this.currentRow) {
        this.handleSendDocumentToUsers(this.currentRow);
      } else if (!this.currentRow) {
        this.$message.error("Please choose a document to continue");
      }
    },
    composeNewFileItem(file) {
      let fileItem = {};
      fileItem.name =
        file.name.includes(".") && file.name.split(".")[0]
          ? file.name.split(".")[0]
          : file.name;
      fileItem.percentage = 0;
      fileItem.raw = file;
      fileItem.size = file.size;
      fileItem.status = "ready";
      fileItem.uid = file.uid;
      return fileItem;
    },
    toDataUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function() {
        callback(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    async getSelectedFileFromSharepoint(url, fileName) {
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${this.sharepointAccessToken}`,
            Accept: "application/json"
          },
          responseType: "blob"
        });
        var self = this;
        self.uploadFromOthersBlobData = response.data;
        self.loading = false;
        self.files.push(
          new self.composeNewFileItem(
            new File([self.uploadFromOthersBlobData], fileName)
          )
        );
      } catch (error) {
        console.error(error);
      }
    },
    handleUploadDoneFromOthers(res) {
      if (
        res.filesUploaded.length > 0 &&
        res.filesUploaded[0].url !== undefined
      ) {
        this.uploadFromOthersBlobData = null;
        this.uploadFromOthersFileName = res.filesUploaded[0].filename;
        this.loading = true;
        var self = this;
        this.toDataUrl(res.filesUploaded[0].url, function(x) {
          self.uploadFromOthersBlobData = x;
          self.loading = false;

          self.files.push(
            new self.composeNewFileItem(
              new File(
                [self.uploadFromOthersBlobData],
                self.uploadFromOthersFileName
              )
            )
          );
        });
      }
    },
    handleUploadFrom(e, fromSources) {
      const client = require("filestack-js").init("AOI3ejHzTcuVrrWTbhHeaz");
      const options = {
        fromSources: fromSources,
        accept: [
          "*.doc",
          "*.pdf",
          "*.docx",
          "*.txt",
          "*.png",
          "*.jpg",
          "*.jpeg",
          "*.xls",
          "*.xlsx",
          "*..xlsm"
        ],
        allowManualRetry: true,
        transformations: {
          crop: false
        },
        customText: {
          "Select Files to Upload": "Select Image to Upload"
        },
        onUploadDone: res => this.handleUploadDoneFromOthers(res)
      };
      client.picker(options).open();
    },
    moveDown(index) {
      if (index == this.files.length - 1) {
        return;
      }

      let sortedUsers = this.swapSingatureUsers(index, index + 1, this.files);
      this.files = [];
      this.files = sortedUsers;
    },
    moveUp(index) {
      if (index == 0) {
        return;
      }

      let sortedUsers = this.swapSingatureUsers(index, index - 1, this.files);
      this.files = [];
      this.files = sortedUsers;
    },
    swapSingatureUsers(fromIndex, toIndex, signaturedUsers) {
      let fromUser = signaturedUsers[fromIndex];
      let toUser = signaturedUsers[toIndex];
      signaturedUsers[fromIndex] = toUser;
      signaturedUsers[toIndex] = fromUser;

      return signaturedUsers;
    },
    async deleteItem(index) {
      this.files.splice(index, 1);
    },
    async checkForMergeOrUpload() {
      /** MIXIN **/
      
      await this.mergeOrUploadFile();
      // let checkAllDocumentsPDF = true;
      // this.files.forEach((file) => {
      //   if (file.raw.type != "application/pdf") {
      //     checkAllDocumentsPDF = false;
      //   }
      // });
      //  if (checkAllDocumentsPDF) {
      //   this.fetchAllDocuments();
      // }     

      await this.fetchAllDocuments();
      this.loading = false;
    },
    async uploadSingleFile(tempUrl) {
      try {
        this.loading = true;

        this.files.forEach((file, index) => {
          if (index == 0) {
            this.fieldsFormUpload.selectedFile =
              file.raw !== undefined ? file.raw : file;
            this.fieldsFormUpload.selectedFileName =
              file.raw !== undefined ? file.raw.name : file.name;
            this.fieldsFormUpload.title = file.name;
          }
        });

        this.fileUploadUrl = tempUrl[0];
        this.fileUploadUrls = tempUrl;
        this.fileNames = this.files.flatMap(e => e.name);
        this.disabledButton = false;
        this.isFileIsUploaded = true;
        this.addCompanyDocument();
      } catch (err) {
        this.loading = false;
      }
    },
    async mergeAllDocs(tempUrl) {
      try {
        this.loading = true;

        this.fileUploadUrl = tempUrl;
        this.fileUploadUrls = [tempUrl];
        this.fileNames = [this.fieldsFormUpload.title];
        this.disabledButton = false;
        this.isFileIsUploaded = true;
        this.addCompanyDocument();
      } catch (err) {
        this.loading = false;
      }
    },

    async addCompanyDocument() {
      try {
        this.loading = true;
        let params = this.prepareParams();

        if (!this.fileUploadUrl) {
          this.$message.error("Please select a document");
          return;
        }

        await Promise.all([
          this.$store.dispatch("documents/addCompanyDocument", params),
          this.$store.dispatch("documentOperations/removePdfFields", {pdfUrls: this.fileUploadUrls})
        ]);
        if (this.getCompanyDocumentAddStatus) {
          await this.uploadSelectedDocument();

          // bus.$emit("document-added", this.fieldsFormUpload.type);
          // this.$emit("document-added", this.fieldsFormUpload.type);
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    titleSort(a, b) {
      if (a.title.toUpperCase() < b.title.toUpperCase()) return -1;
      if (a.title.toUpperCase() > b.title.toUpperCase()) return 1;
      return 0;
    },
    prepareParams() {
      let params = {};
      params.file_upload_url = this.fileUploadUrl;
      params.file_upload_urls = this.fileUploadUrls;
      params.file_names = this.fileNames;
      params.title = this.fieldsFormUpload.title;
      params.type = this.fieldsFormUpload.type;
      return params;
    },

    handleClose() {
      this.dialogVisible = false;
    },
    async beforeLogoUpload(file) {
      console.log(file);
    },
    uploadFile(file) {
      if (
        file.raw.type != "audio/mpeg" &&
        file.raw.type != "video/mp4" &&
        file.raw.type != "image/gif" &&
        file.raw.type != "text/html" &&
        file.raw.type != "text/css" &&
        file.raw.type != "text/javascript" &&
        file.raw.type != "application/json" &&
        file.raw.type != "" &&
        file.raw.type != "application/x-msdownload"
      ) {
        if (file.size < 25000000) {
          this.files.push(file);
        } else {
          this.$message.error("Large file. Maximum upload file size: 25 MB");
        }
      } else {
        this.$message("File format is not supported");
      }
    },
    async uploadUserFile(file) {
      var formData = new FormData();
      formData.append("uploadedFiles", file.raw);
      if (
        file.raw.type == "application/pdf" ||
        file.raw.type == "image/jpeg" ||
        file.raw.type == "image/png" ||
        file.raw.type == "image/jg"
      ) {
        await this.$store.dispatch("fileUpload/uploadUserFile", formData);
      } else if (
        file.raw.type ==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.raw.type == "application/msword"
      ) {
        await this.$store.dispatch("fileUpload/uploadUserDocxFilev1", formData);
      }

      this.loading = false;
      if (this.getUploadFileDataStatus) {
        this.fileUploadUrl = this.getUploadFileDataStatus.url;
        this.disabledButton = false;
      } else {
        4;
        this.logoError = "Error in uploading file. Please try again..";
      }
    },
    async documentFileupload() {
      let params = this.prepareParams();
      await this.$store.dispatch("documents/addCompanyDocument", params);
      if (this.getCompanyDocumentAddStatus) {
        if (this.getNewCompanyDocument) {
          await this.$store.dispatch(
            "documents/fetchConfigureDocumentIdByDocumentId",
            this.getNewCompanyDocument._id
          );
          if (this.getCreateConfigureDocumentData) {
            bus.$emit("document-added", this.fieldsFormUpload.type);
            this.$emit("document-added", this.fieldsFormUpload.type);
            this.$notify.success({
              title: "Success",
              message: "Document created successfully"
            });
            let obj = {};
            obj[
              this.getCreateConfigureDocumentData.configurable_document._id
            ] = this.pdfFormFields;
            await this.$store.commit(
              "documents/setDocumentFormFieldsData",
              obj,
              {
                root: true
              }
            );
            this.$router.push({
              name: "edit-configure-document-image",
              params: {
                document_id: this.getNewCompanyDocument._id,
                configurable_document_id: this.getCreateConfigureDocumentData
                  .configurable_document._id
              },
              query: this.$route.query
            });
          } else {
            this.$message("Sorry ! Error while creating configurable document");
          }
        } else {
          this.$message("Sorry ! Error while file uploading");
        }
      }
    },

    async getFileUploadUrl(file) {
      let data = {
        file_name: file.raw.name,
        file_type: file.raw.type,
        file_size_in_kb: file.raw.size / 1000
      };
      await this.$store.dispatch("documents/fetchDocumentUploadUrl", data);
    },
    async uploadSelectedDocument() {
      this.loading = true;
      this.loadingText = "Uploading Document...";
      if (this.getNewCompanyDocument) {
        await this.$store.dispatch(
          "documents/fetchConfigureDocumentIdByDocumentId",
          this.getNewCompanyDocument._id
        );
        if (this.getCreateConfigureDocumentData) {
          this.$notify.success({
            title: "Success",
            message: "Document created successfully"
          });
          let obj = {};
          obj[
            this.getCreateConfigureDocumentData.configurable_document._id
          ] = this.pdfFormFields;
          await this.$store.commit("documents/setDocumentFormFieldsData", obj, {
            root: true
          });
          this.$router.push({
            name: "edit-configure-document-image",
            params: {
              document_id: this.getNewCompanyDocument._id,
              configurable_document_id: this.getCreateConfigureDocumentData
                .configurable_document._id
            },
            query: this.$route.query
          });
        } else {
          this.$message("Sorry ! Error while creating configurable document");
        }
      } else {
        this.$message("Sorry ! Error while file uploading");
      }
      this.loading = false;
    },
    cancel() {
      this.$emit("document-cancelled");
    },
    clearFormData() {
      this.fieldsFormUpload = {
        title: "",
        description: "",
        type: "",
        category: "",
        groups: [],
        file_ref_id: "",
        selectedFile: "",
        selectedFileName: "",
        e_signature_value: "",
        e_signature: ""
      };
    },
    async getDocumentsFromSharepoint() {
      try {
        const registeredUser = await axios.get("/get-app-integration-data");
        this.applicationData = registeredUser.data.data;
        if (registeredUser.data.success) {
          this.loading = true;
          this.sharepointDataDialog = true;
          const response = await axios.post(
            "/documents/getSharePointAccessToken"
          );
          this.sharepointAccessToken = response.data.data.access_token;
          this.path = "";
          await this.fetchAllDocumentsFromSharePoint(
            this.sharepointAccessToken
          );
          this.loading = false;
        } else {
          this.redirectDialog = true;
        }
      } catch (error) {
        this.loading = false;
        this.sharepointDataDialog = false;
        this.$notify.error({
          title: "Error",
          message: "An Error Occurred during the Authentication"
        });
      }
    },
    async fetchAllDocumentsFromSharePoint(accessToken, path = "") {
      try {
        this.sharepointData = [];
        let apiUrl = `${this.applicationData.app_data.siteUrl}/sites/${this.applicationData.app_data.siteName}/_api/Web/GetFolderByServerRelativePath(decodedurl='/sites/${this.applicationData.app_data.siteName}/Shared%20Documents')?$expand=Folders,Files`;
        if (path) {
          apiUrl = `${this.applicationData.app_data.siteUrl}/sites/${this.applicationData.app_data.siteName}/_api/Web/GetFolderByServerRelativePath(decodedurl='/sites/${this.applicationData.app_data.siteName}/Shared%20Documents${path}')?$expand=Folders,Files`;
        }
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json"
          }
        });
        const folderData = response.data;
        const folders = folderData.Folders;
        const files = folderData.Files;
        folders.forEach(folder => {
          if (folder.Name !== "Forms") {
            const document = {
              file: folder.Name,
              lastModified: folder.TimeLastModified,
              fileUrl: folder.ServerRelativeUrl,
              type: "folder"
            };
            this.sharepointData.push(document);
          }
        });
        files.forEach(file => {
          if (
            file.Name !== "__siteIcon__.jpg" &&
            file.Name !== "__siteIcon__.png"
          ) {
            const document = {
              file: file.Name,
              fileUrl: file.ServerRelativeUrl,
              lastModified: file.TimeLastModified,
              type: "file"
            };
            this.sharepointData.push(document);
          }
        });
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message: "Error Fetching Documents"
        });
      }
    },
    uploadDocumentFromSharepoint(row) {
      this.sharepointDataDialog = false;
      let documentUrl = `${this.applicationData.app_data.siteUrl}/sites/${this.applicationData.app_data.siteName}/_api/web/GetFolderByServerRelativeUrl('/sites/${this.applicationData.app_data.siteName}/Shared%20Documents')/Files('${row.file}')/$value`;
      if (this.path) {
        documentUrl = `${this.applicationData.app_data.siteUrl}/sites/${this.applicationData.app_data.siteName}/_api/web/GetFolderByServerRelativeUrl('/sites/${this.applicationData.app_data.siteName}/Shared%20Documents${this.path}')/Files('${row.file}')/$value`;
      }
      this.uploadFromOthersBlobData = null;
      this.uploadFromOthersFileName = row.file;
      this.loading = true;
      this.loading = true;
      this.getSelectedFileFromSharepoint(
        documentUrl,
        this.uploadFromOthersFileName
      );
    },
    handleSizeChange(newSize) {
      this.dialogTablePageSize = newSize;
      this.dialogTableCurrentPage = 1; // Reset current page to 1
    },
    handleCurrentPageChange(newPage) {
      this.dialogTableCurrentPage = newPage;
    },
    handlePath(folderName) {
      this.path = `${this.path}/${folderName}`;
      this.fetchAllDocumentsFromSharePoint(
        this.sharepointAccessToken,
        this.path
      );
    },
    handleBreadcrumbClick(folder) {
      const breadcrumbIndex = this.pathArray.indexOf(folder);
      if (breadcrumbIndex !== -1) {
        this.path = this.pathArray.slice(0, breadcrumbIndex + 1).join("/");
        this.fetchAllDocumentsFromSharePoint(
          this.sharepointAccessToken,
          this.path
        );
      } else if (folder === "") {
        this.path = "";
        this.fetchAllDocumentsFromSharePoint(this.sharepointAccessToken);
      } else {
        // Navigate backward
        const lastIndex = this.path.lastIndexOf("/");
        if (lastIndex !== -1) {
          this.path = this.path.substr(0, lastIndex);
          this.fetchAllDocumentsFromSharePoint(
            this.sharepointAccessToken,
            this.path
          );
        }
      }
    },
    isRowSelectable(row) {
      return row.type !== "folder";
    },
    handleSelectionChange(selection) {
      this.selectedRows = selection;
      this.isSingleFile = this.selectedRows.length <= 0;
    },
    uploadSelectedItems() {
      this.selectedRows.forEach(row => {
        this.uploadDocumentFromSharepoint(row);
      });
    }
    /* watch: {
    search_string: function (val) {
      this.search_string = val;
      this.fetchTemplates();
    }
      },*/
  },
  watch: {
    search_string: function(val) {
      this.search_string = val;
      this.fetchTemplates();
    }
  },
  beforeDestroy() {
    this.$store.commit("documents/setFileUploadURL", null, { root: true });
    this.$store.commit("documents/setDraftDocumentCreateStatus", null, {
      root: true
    });
    this.$store.commit("documents/setCompanyDocumentAddStatus", null, {
      root: true
    });
    //  this.$store.commit("documents/setNewCompanyDocument",null,{root:true})
    this.$store.commit("documents/setCreateConfigureDocumentData", null, {
      root: true
    });
    this.$store.commit("documents/setAllTemplates", null, { root: true });
    this.$store.commit("documents/setDraftDocumentCreateData", null, {
      root: true
    });
    this.$store.commit("documents/setDocumentSaveAsTemplate", null, {
      root: true
    });
    this.$store.commit("s3FileUpload/setDocumentUploadStatus", null, {
      root: true
    });
    //this.$store.commit( "configurableDocuments/setConfigurableDocumentDataById",null,{root:true})
    this.$store.commit(
      "employeeDocuments/setAuthorizedRepresentativeSignatureStatus",
      null,
      { root: true }
    );
    this.$store.commit(
      "employeeDocuments/setDocumentUserSignatureStatus",
      null,
      { root: true }
    );
    this.$store.commit("employeeDocuments/setDocumentApprovalStatus", null, {
      root: true
    });
    this.$store.commit(
      "employeeDocuments/setDocumentCompletedAfterApproval",
      null,
      { root: true }
    );
    this.clearFormData();
  }
};
</script>

<style lang="scss">
.mobile {
  #uploadDocsPrefernce {
    .name-types .filename .direction-buttons {
      left: 85%;
    }
    .flex-center {
      display: flex;
      justify-content: center;
    }
    .el-upload-dragger {
      width: 200px !important;
      padding: 5px 5px 19px 5px !important;
      margin-top: 0px !important;
    }
    .block-types {
      // .upload-file {
      //   width: 200px;
      //   height: 100%;
      // }
      .upload-template {
        width: 200px;
        height: 213px;
        .icon {
          padding-top: 0px !important;
          margin-top: 0px !important;
        }
        .el-button {
          padding: 0px !important;
          height: 220px !important;
        }
        .d-block {
          height: 220px;
        }
      }
    }
  }
}
.doc-modal {
  .el-dialog__header {
    .el-dialog__title {
      text-transform: uppercase;
    }
  }
  .error {
    color: red;
  }
}
#uploadDocsPrefernce {
  .select-your-preference-upload-file {
      .upload-file-container {
        transition: transform 0.3s ease-in-out;

        &:hover {
          transform: scale(1.04); /* You can adjust the scale factor as needed */
        }

        .upload-from-other-container {
          .from-others {
            .image {
              transition: transform 0.3s ease-in-out;

              &:hover {
                transform: scale(1.50); /* You can adjust the scale factor as needed */
              }
            }
          }
        }
      }
    }
  .el-dialog__body {
    overflow-y: hidden;
    scrollbar-width: none !important;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
  .upload-template {
    width: 358px;
    height: 300px;
    .icon {
      margin-top: -56px;
    }
    .d-block {
      height: 300px;
    }
  }
  padding-top: 2%;
  .block-types {
    .upload-file {
      height: 262px;
      // .el-upload.el-upload--text {
      //   height: inherit;
      //   width: 100%;
      //   display: flex;
      //   justify-content: flex-end;
      // }
    }
    .d-block {
      .el-button {
        background-color: #efefef;
        // max-width: 360px;
        &:hover {
          h4 {
            color: #f754a2;
          }
          border-color: #f754a2;
        }
      }
    }
  }
  .name-types {
    margin-top: 2%;
    .filename {
      height: 56px;
      margin-top: 1%;
      cursor: pointer;
      background: #ffffff;
      border: 1px solid #dfe3eb;
      box-sizing: border-box;
      border-radius: 2px;
      .el-upload-list__item-name {
        margin-top: 1em;
        .img-active {
          visibility: hidden;
          width: 6px !important;
          margin-right: 10px;
          margin-left: 10px;
          position: relative;
          top: 3px;
        }
        .img-fluid {
          position: relative;
          top: 5px;
          width: 18.43px !important;
        }
      }
      // .delete-doc {
      //   visibility: hidden;
      // }
      .direction-buttons {
        display: flex;
        visibility: hidden;
        position: relative;
        top: -35%;
        left: 97%;
        .directions {
          position: relative;
          right: 10%;
          top: -10px !important;
        }
      }
      &:hover {
        .img-active {
          visibility: visible;
          width: 6px !important;
          margin-right: 10px;
          margin-left: 10px;
          position: relative;
          top: 3px;
        }
        .direction-buttons {
          display: flex;
          visibility: visible;
          position: relative;
          top: -35%;
          left: 97%;
          .directions {
            position: relative;
            right: 10%;
            top: -10px !important;
          }
        }
        // .delete-doc {
        //   visibility: visible;
        //   position: relative;
        //   top: -60%;
        //   left: 97%;
        // }
      }
    }
  }
  .upload-btn {
    margin-top: 10px;
    position: relative;
    bottom: 0px;
    align-items: center;
  }
  // .el-upload-dragger {
  //   border: 1px solid #dcdfe6;
  //   max-width: 100%;
  //   height: inherit;
  //   padding-top: 2.5em;
  //   &:hover {
  //     h4 {
  //       color: #f754a2;
  //     }
  //     border-color: #f754a2;
  //   }
  // }
  @media (max-width: 991.98px) {
    .el-row-center-items {
      .el-col {
        margin-bottom: 20px;
        text-align: center !important;
        .el-upload {
          justify-content: center !important;
        }
        .d-block {
          justify-content: center !important;
          display: flex !important;
        }
      }
    }
    .upload-btn {
      margin-left: -30px;
    }
  }

  .select-your-preference-upload-file {
    display: flex;
    justify-content: center;
    .upload-file-container {
      &:hover {
        border: 1px solid #f754a2;
      }
      @media (min-width: 991.98px) {
        min-height: 336px;
      }
      // max-width: 360px;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      .upload-file-el-upload {
        .block-types {
          .upload-file {
            width: 200px;
            height: 100%;
          }
        }
        .upload-a-file-bottom-text {
          border-bottom: 2px solid #ddd3d3;
          padding: 0 0 10px 0;
          margin: 0px 10px;
        }
        @media (max-width: 991.98px) {
          .el-upload-dragger {
            height: 207px !important;
          }
        }
        .el-upload-dragger {
          border: none;
          width: 360px;
          margin-top: 40px;
        }
      }
      .upload-from-other-container {
        text-align: center;
        .el-upload {
          border: none !important;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;

          &:hover {
            border: none;
          }
        }
        .upload-a-file-upload-from-text {
          margin-top: -30px;
        }
        .from-others {
          display: flex;
          justify-content: center;
          .image {
            cursor: pointer;
            margin-left: 10px;
            margin-right: 10px;
            .upload-icon {
              width: 25px;
              height: 25px;
            }
            @media (max-width: 991.98px) {
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }
  .upload-template {
    .d-block {
      min-height: 336px;
    }
  }
  .select-your-preference {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
#uploadDocsPrefernce {
  .upload-a-blank-document-popup {
    // max-width: 530px;
    .btn-wrapper {
      display: flex;
      margin-top: 20px;
      margin-bottom: 18px;
      justify-content: right;
    }
    @media (max-width: 700px) {
      width: 100%;
      // @media (max-width: 600px) {
      //   width: 95%;
      //   @media (max-width: 500px) {
      //     width: 100%;
      //   }
      // }
      .el-dialog {
        width: 90%;
      }
    }
    .el-dialog {
      max-width: 600px;
    }
  }
}
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.breadcrumb-item {
  cursor: pointer;
  color: #409eff;
}

.breadcrumb-separator {
  margin: 0 5px;
  color: #909399;
}

.folder-column {
  cursor: pointer;
}
</style>../../config/options